import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { DocSearchRequest } from "src/app/_shared/models/publication-results.model";
import { Subscription } from "rxjs";
import { DefaultDataset } from "src/app/shared/models/dataset.model";
import { Router } from "@angular/router";
import { PublicationService } from "../publication-refinecontent/publication-results/publication-refinement.service";
import { HttpErrorResponse } from "@angular/common/http";
import { MatDialogRef } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { DatasetService } from "src/app/services/dataset/dataset.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "tdms-publication-createdataset",
  templateUrl: "./publication-createdataset.component.html",
  styleUrls: ["./publication-createdataset.component.scss"],
})
export class PublicationCreatedatasetComponent implements OnInit {
  isLoading: boolean = true;
  isQuery: boolean = false;
  isShowQuery: boolean = false;
  isCreating: boolean = false;
  isError: boolean = false;
  errorMessage: string = "";

  UNKOWNERROR: string = "An error has occurred. Please try agian.";
  NONAME: string = "Please enter a name.";
  INVALIDNAME: string = "The field you entered is invalid. Please try again";

  createDataset: any;
  docCount: string;
  publications: string;
  query: string;
  pubResponse: any;
  searchRequest: DocSearchRequest;
  creationSubscription: Subscription;
  pubResponseSubcription: Subscription;
  workbenchId: string;

  constructor(
    private formBuilder: FormBuilder,
    private pubService: PublicationService,
    private datasetService: DatasetService,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    this.createDataset = this.formBuilder.group({
      name: "",
      description: "",
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Create new dataset: Provide dataset details");
    this.searchRequest = JSON.parse(localStorage.getItem("sReq"));
    this.docCount = localStorage.getItem("docCount");
    if (this.searchRequest.search.query) {
      this.query =
        this.searchRequest.search.query.length > 300
          ? this.searchRequest.search.query.substring(0, 300) + "..."
          : this.searchRequest.search.query;
    } else {
      this.query = "";
    }
    this.isQuery = this.query !== "";
    this.isLoading = false;
  }

  ngDestroy() {
    if (this.creationSubscription) {
      this.creationSubscription.unsubscribe();
    }
    if (this.pubResponseSubcription) {
      this.pubResponseSubcription.unsubscribe();
    }
  }

  getTrueDatasetType(): string {
    return this.datasetService.getDataSetType();
  }

  getDatasetType(): string {
    return this.datasetService.getDataSetType();
  }

  getContentList(): string {
    if (this.getDatasetType() == "Publication") {
      const pubList = JSON.parse(localStorage.getItem("pubList"));
      this.savePublicationData(pubList);
      return pubList
        .map(
          (pub) => pub.field.find((element) => element.name === "title").value
        )
        .join(", ");
    } else if (this.getDatasetType() == "Database") {
      return this.pubService
        .getSelectedDbList()
        .map((db) => db.name)
        .join(", ");
    }
    return "";
  }

  savePublicationData(pubList) {
    this.searchRequest.search.publications.map((pubId) => {
      var publication = pubList.find((pub) => pub.id === pubId.searchValue);
      pubId.displayValue = publication.field
        .find((element) => element.name === "title")
        .value.toString();
    });
  }

  toggleQuery() {
    this.isShowQuery = !this.isShowQuery;
  }

  onCreate(datasetData) {
    var regex = /^[a-zA-Z0-9-\s]*$/;
    if (!datasetData.name) {
      this.isError = true;
      this.errorMessage = this.NONAME;
    } else if (!regex.test(datasetData.name)) {
      this.isError = true;
      this.errorMessage = this.INVALIDNAME;
    } else {
      this.isCreating = true;
      this.createNewDataset(datasetData);
    }
  }

  createNewDataset(datasetData) {
    
    this.creationSubscription = this.pubService
      .createDataset(
        this.getDataset(datasetData.name, datasetData.description)
      )
      .subscribe(
        (response) => {
          localStorage.setItem("created", "true");
          this.dialog.open(SuccessModal, {
            disableClose: true,
            maxWidth: "850px",
          });
          this.isCreating = false;
          this.clearStorage();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 500) {
            this.isError = true;
            this.errorMessage = this.UNKOWNERROR;
          }
          this.isCreating = false;
        }
      );
  }

  private clearStorage() {
    var clearKeys: string[] = [
      "datasetType",
      "docCount",
      "length",
      "selectedDbList",
      "sReq",
      "pubList",
      "searchValue",
    ];
    clearKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  private getDataset(
    datasetname: string,
    datasetDesription: string
  ): DefaultDataset {
    const dataset: DefaultDataset = {
      workbenchId: localStorage.getItem("workbench"),
      name: datasetname,
      description: datasetDesription,
      search: this.searchRequest.search,
    };
    console.log(dataset);
    return dataset;
  }
}

@Component({
  selector: "success-modal",
  templateUrl: "success-modal.html",
  styleUrls: ["./publication-createdataset.component.scss"],
})
export class SuccessModal {
  constructor(
    public dialogRef: MatDialogRef<SuccessModal>,
    private router: Router
  ) {}

  onSuccess() {
    this.router.navigateByUrl("/workbenchdashboard");
    localStorage.removeItem("sReq");
    this.dialogRef.close();
  }
}
