import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "tdms-doc-limit-modal",
  templateUrl: "./doc-limit-modal.component.html",
  styleUrls: ["./doc-limit-modal.component.scss"],
})
export class DocLimitModalComponent implements OnInit {
  @ViewChild("modal_x", { static: true }) firstTabIndex: any;
  @ViewChild("modal_button", { static: false }) lastTabIndex: any;

  constructor(
    public dialogRef: MatDialogRef<DocLimitModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log("data passed to dialog component", JSON.stringify(data));
  }

  ngOnInit() {}

  loopTabindex(index: string, event: KeyboardEvent) {
    if (index === "lastTabIndex") {
      event.preventDefault();
      this.firstTabIndex.nativeElement.focus();
    } else if (event.shiftKey && event.key == "Tab") {
      event.preventDefault();
      this.lastTabIndex.nativeElement.focus();
    }
  }
}
