import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { from, Subscription } from "rxjs";
import {
  Filter,
  Search,
  VizDocSearchRequest,
} from "src/app/_shared/models/publication-results.model";
import { Method, Methods, VizPreProject } from "../../models/project.model";
import { VizPublicationService } from "../analysis-refine-content/analysis-refinement.service";
import { map, take, toArray } from "rxjs/operators";
import { DbFilter, PubFilter } from "../../models/viz.models";

@Component({
  selector: "tdms-analysis-create-content",
  templateUrl: "./analysis-create-content.component.html",
  styleUrls: ["./analysis-create-content.component.scss"],
})
export class AnalysisCreateContentComponent implements OnInit, AfterViewInit {
  isLoading: boolean = true;
  isQuery: boolean = false;
  isShowQuery: boolean = false;
  isCreating: boolean = false;
  isError: boolean = false;
  errorMessage: string = "";

  NONAME: string = "Please enter a name.";
  INVALIDNAME: string = "The field you entered is invalid. Please try again";

  createProject: any;
  docCount: string;
  publications: string;
  query: string;
  pubResponse: any;
  searchRequest: VizDocSearchRequest;
  creationSubscription: Subscription;
  pubResponseSubcription: Subscription;
  workbenchId: string;
  selectedVizText: string;
  @ViewChild("datasetNameInput", { static: false }) inputRef!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private pubService: VizPublicationService,
    private dialog: MatDialog
  ) {
    this.createProject = this.formBuilder.group({
      name: "",
    });
  }

  ngOnInit() {
    this.searchRequest = JSON.parse(localStorage.getItem("sReq"));
    this.docCount = localStorage.getItem("docCount");
    this.isLoading = false;
    this.selectedVizText = this.getSelectedVizualizations();

    if (this.searchRequest.search.query) {
      this.query =
        this.searchRequest.search.query.length > 300
          ? this.searchRequest.search.query.substring(0, 300) + "..."
          : this.searchRequest.search.query;
    } else {
      this.query = "";
    }
    this.isQuery = this.query !== "" && this.query.length > 0;
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  ngDestroy() {
    if (this.creationSubscription) {
      this.creationSubscription.unsubscribe();
    }
    if (this.pubResponseSubcription) {
      this.pubResponseSubcription.unsubscribe();
    }
  }

  getContentList(contentType: string): string {
    if (contentType == "db") {
      let dbFilterLocalItem = localStorage.getItem("dbFilters");
      if (dbFilterLocalItem != undefined) {
        let dbFilters: DbFilter[] = JSON.parse(
          localStorage.getItem("dbFilters")
        )
          ? JSON.parse(localStorage.getItem("dbFilters"))
          : [];
        //dbFacets.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())); //Sort in alphabetical order.

        if (dbFilters.length > 20) {
          dbFilters.length = 20;
          return (
            dbFilters
              .sort()
              .map((item) => item.name)
              .join(", ") + "..."
          );
        }
        return dbFilters
          .sort()
          .map((item) => item.name)
          .join(", ");
      }
    } else if (contentType == "pub") {
      let pubFilterLocalItem = localStorage.getItem("pubFilters");
      if (pubFilterLocalItem != undefined) {
        let pubFilters: PubFilter[] = JSON.parse(
          localStorage.getItem("pubFilters")
        )
          ? JSON.parse(localStorage.getItem("pubFilters"))
          : [];
        if (pubFilters.length > 20) {
          pubFilters.length = 20;
          return (
            pubFilters
              .sort()
              .map((item) => item.title)
              .join(", ") + "..."
          );
        }
        return pubFilters
          .sort()
          .map((item) => item.title)
          .join(", ");
      }
    }
    return "";
  }

  isPubFiltersExists(): boolean {
    let item = localStorage.getItem("pubFilters");
    if (item === null) {
      return false;
    } else {
      return JSON.parse(item).length > 0;
    }
    //return false
  }

  isDbFiltersExists(): boolean {
    let item = localStorage.getItem("dbFilters");

    if (item !== null) {
      return JSON.parse(item).length > 0;
    }
    return false;
  }

  toggleQuery() {
    this.isShowQuery = !this.isShowQuery;
  }

  onCreate(projectData) {
    if (!projectData.name) {
      this.isError = true;
      this.errorMessage = this.NONAME;
    } else {
      this.isCreating = true;
      this.creationSubscription = this.pubService
        .createProject(this.getProject(projectData.name))
        .subscribe(
          (response) => {
            localStorage.setItem("vizCreated", projectData.name);
            this.dialog.open(ProjectCreateSuccessModal, {
              disableClose: true,
              maxWidth: "850px",
            });
            this.isCreating = false;
          },
          (error: HttpErrorResponse) => {
            console.log("Error: ", error);
            this.isCreating = false;
          }
        );
    }
  }

  private getProject(projectname: string): VizPreProject {
    const search = this.addToDisplayPropertiesToFilters(
      this.searchRequest.search
    );

    const project: VizPreProject = {
      name: projectname,
      search: search,
      methods: this.selectedMethods(),
    };

    return project;
  }

  private selectedMethods(): Methods {
    let method: Method = { methodType: "" };
    let methods: Method[] = [];
    let allSelectedMethods: Methods = { method: methods };
    let vizSelection: {
      geoCardSelection: boolean;
      tmCardSelection: boolean;
      saCardSelection: boolean;
    } = JSON.parse(localStorage.getItem("vizcardselection"));
    if (vizSelection.geoCardSelection === true) {
      method.methodType = "geography";
      methods.push(method);
    }
    if (vizSelection.tmCardSelection === true) {
      method = { methodType: "" };
      method.methodType = "topic";
      methods.push(method);
    }
    if (vizSelection.saCardSelection === true) {
      method = { methodType: "" };
      method.methodType = "sentiment";
      methods.push(method);
    }
    allSelectedMethods.method = methods;
    console.log(
      "allselected Methods ------> " + JSON.stringify(allSelectedMethods)
    );
    return allSelectedMethods;
  }

  addPubsToSearch() {
    let pubList = JSON.parse(localStorage.getItem("pubFilters"));
    let entryList = [];
    pubList.forEach((pub) => {
      entryList.push({
        searchValue: pub.id,
      });
    });
    let pf = {
      name: "pubTitle",
      entries: pubList,
    };
    this.searchRequest.search.filters.push(pf);
  }

  getSelectedVizualizations() {
    let rtnText = "";
    let cardObj = JSON.parse(localStorage.getItem("vizcardselection"));
    //let obj = {'geoCardSelection': this.geoCardCheck, 'tmCardSelection': this.tmCardCheck};
    if (cardObj.geoCardSelection === true) {
      rtnText = "Geographic Analysis";
    }
    if (rtnText.length > 0 && cardObj.tmCardSelection === true) {
      rtnText = rtnText + ", " + "Topic Modeling";
    } else if (cardObj.tmCardSelection === true) {
      rtnText = "Topic Modeling";
    }
    if (rtnText.length > 0 && cardObj.saCardSelection === true) {
      rtnText = rtnText + ", " + "Sentiment Analysis";
    } else if (cardObj.saCardSelection === true) {
      rtnText = "Sentiment Analysis";
    }

    return rtnText;
  }

  private addToDisplayPropertiesToFilters(currentSearch: Search): Search {
    const pubDbDisplayFilters: Filter[] = this.populateToDisplayProps();
    let modifiedSearch = currentSearch;
    modifiedSearch.filters.push(...pubDbDisplayFilters);
    return modifiedSearch;
  }

  private populateToDisplayProps(): Filter[] {
    console.log("populating toDisplay properties");
    let top10Db_pubFacets: Filter[] = [];
    const pubFilters = localStorage.getItem("pubFilters");
    const dbFacets = localStorage.getItem("dbFilters");

    if (pubFilters != undefined && pubFilters != null) {
      from(JSON.parse(pubFilters) === null ? [] : JSON.parse(pubFilters))
        .pipe(
          take(10),
          map((pubEntry: any) => ({ searchValue: pubEntry.title })),
          toArray()
        )
        .subscribe((res) => {
          if (res && res.length > 0) {
            let pubToDisplay = {
              name: "pubTitlesToDisplay",
              entries: res,
            };
            top10Db_pubFacets.push(pubToDisplay);
          }
        });
    }

    if (dbFacets != undefined && dbFacets != null) {
      from(JSON.parse(dbFacets) === null ? [] : JSON.parse(dbFacets))
        .pipe(
          take(10),
          map((dbEntry: any) => ({ searchValue: dbEntry.name })),
          toArray()
        )
        .subscribe((res) => {
          if (res && res.length > 0) {
            let dbsToDisplay = {
              name: "datasourcesToDisplay",
              entries: res,
            };
            top10Db_pubFacets.push(dbsToDisplay);
          }
        });
    }

    return top10Db_pubFacets;
  }

  private setFocus() {
    this.inputRef.nativeElement.focus();
  }
}

@Component({
  selector: "project-create-success-modal",
  templateUrl: "project-create-success-modal.html",
  styleUrls: ["./analysis-create-content.component.scss"],
})
export class ProjectCreateSuccessModal {
  constructor(
    public dialogRef: MatDialogRef<ProjectCreateSuccessModal>,
    private router: Router
  ) {}

  onSuccess() {
    this.router.navigateByUrl("/analysis/dashboard");
    this.clearStorage();
    this.dialogRef.close();
  }

  private clearStorage() {
    var clearKeys: string[] = [
      "vDocCount",
      "vsReq",
      "vPubList",
      "searchTerm",
      "vPubFilters",
      "pubFacets",
    ];
    clearKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }
}
