import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { currentId } from 'async_hooks';
import { WorkbenchDataService } from 'src/app/services/workbench-dashboard/workbench-data.service';
import { DatasetService } from 'src/app/services/dataset/dataset.service';

@Component({
  selector: 'Dataset-Header',
  templateUrl: './dataset-header.component.html',
  styleUrls: ['./dataset-header.component.scss']
})
export class DatasetHeaderComponent implements OnInit {

  @Input() stage:string;

  workbench;
  image = 1;
  private contentStatusSubscription: Subscription;
  private datasetStatusSubscription: Subscription;
  private workbenchSubscription: Subscription;

  constructor(private datasetService: DatasetService, private workbenchDataService: WorkbenchDataService) { }

  ngOnInit() {
    this.setBackgroundImage();
    this.workbenchSubscription = this.workbenchDataService.currentWorkbenchId$.subscribe(currentId => {this.workbench = currentId});
    if (this.workbench === 'default workbenchId') {
      this.workbench = localStorage.getItem('workbench') !== null ? localStorage.getItem('workbench') :  'default workbenchId';
      //this.workbenchDataService.newWorkbenchId(this.workbench);
    } else {
      localStorage.setItem('workbench', this.workbench);
      //this.workbenchDataService.newWorkbenchId(this.workbench);

    }
  }

  public setBackgroundImage() {
    this.image = (Math.floor(Math.random() * 5) + 1);
  }

  onDestroy() {
    this.workbenchSubscription.unsubscribe();
    this.contentStatusSubscription.unsubscribe();
    this.datasetStatusSubscription.unsubscribe();
  }

  getRodClass():string{
    switch(this.stage) { 
      case 'refine': { 
        return 'highlighted rod';
         break; 
      } 
      case 'create': { 
        return 'highlighted rod';
         break; 
      }
      default: { 
         return 'unhighlighted rod'; 
         break; 
      } 
   }
  }

}
