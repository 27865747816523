import { Component, isDevMode, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subject,
  Subscription,
} from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Event, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { AnalysisAccount } from "../analysis/models/account.model";
import { AuthService } from "../auth/auth.service";
import { StudioUserSignupService } from "../auth/studio-user-signup.service";
import { catchError, debounceTime, map, switchMap, tap } from "rxjs/operators";
import { UserStatus } from "../analysis/models/user.model";

@Component({
  selector: "tdms-create-account",
  templateUrl: "./create-account.component.html",
  styleUrls: ["./create-account.component.scss"],
})
export class CreateAccountComponent implements OnInit {
  createAccountForm: FormGroup;
  isShowPassword = true;
  isAccountCreated = false;
  criteria: boolean[] = [false, false, false, false, false];
  currentEmailInput: string = "";

  isAccountExistsPlatformOnly = false;
  displayExistingAcctModal = false;
  showTrailBlock = false;

  identityCheckDone = false;
  hasSubscriptionCheckDone = false;
  hasSubscription = false;

  isEmailCheckLoading = false;
  isAccessCheckLoading = false;

  institutionList: AnalysisAccount[] = [];
  emailSubscription: Subscription;
  authSubscription: Subscription;
  dialogSubscription: Subscription;

  private EXISTS = "exists";
  private DON_NOT_EXISTS = "dne";
  private UNVERIFIED = "unverified";
  private VERIFIED = "verified";

  errMsg: any;

  private emailSubjectSubscription: Subscription;
  private emailSubject = new Subject<string>();

  private passwordValidators = [
    Validators.minLength(8),
    Validators.pattern(/\d/), //check weather password has a number
    Validators.pattern(/[A-Z]/), //check whether the entered password has upper case letter
    Validators.pattern(/[a-z]/), //check whether the entered password has lower case letter
    Validators.pattern(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/), //check whether the entered password has lower case letter
  ];

  private emailValidators = [
    Validators.pattern(/^\w+([\.\+\-]?\w+)*\@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
    Validators.required,
  ];

  auth0AccountExists$ = new BehaviorSubject<boolean>(false);

  constructor(
    private formBuilder: FormBuilder,
    private studioUserSignupService: StudioUserSignupService,
    private dialog: MatDialog,
    private route: Router,
    private location: Location,
    private titleService: Title
  ) {}

  ngOnInit() {
    if (this.isAccountCreated) {
      this.titleService.setTitle("Verify Your Email");
    } else {
      this.titleService.setTitle("Create Account");
    }

    this.emailSubjectSubscription = this.emailSubject
      .pipe(debounceTime(300))
      .subscribe((emailInputValue) => {
        this.checkEmail(emailInputValue);
      });

    this.createAccountForm = this.formBuilder.group({
      email: ["", this.emailValidators],
      password: ["", this.passwordValidators],
      confirmPassword: "",
      institution: ["", Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
    });

    this.createAccountForm.get("acceptTerms").valueChanges.subscribe((val) => {
      console.log("AcceptTerm valueChange ===>", val);
      let ctrl = this.createAccountForm.get("password");
    });

    this.auth0AccountExists$.subscribe((exists) => {
      console.log("Auth0-Account-exists", exists);
      if (exists) {
        this.createAccountForm
          .get("password")
          .setValidators(this.passwordValidators);
      } else {
        this.createAccountForm
          .get("password")
          .setValidators(this.passwordValidators.concat(Validators.required));
      }
      this.createAccountForm.controls["password"].updateValueAndValidity();
    });
  }

  onSubmit(userData) {
    if (this.createAccountForm.valid) {
      console.log(
        "Form is valid ...B4 submitting...",
        JSON.stringify(this.createAccountForm.value, null, 2)
      );
      this.createAccount(userData);
    }
  }

  onSubmitMr(userData) {
    if (this.createAccountForm.valid) {
      console.log(
        "Form is valid ...B4 submitting...MR createAccount",
        JSON.stringify(this.createAccountForm.value, null, 2)
      );
      this.createAccount(userData);
    }
  }

  onKeyup(emailInput: string, event: KeyboardEvent) {
    if (event.key === "Tab" || event.key === "Shift") {
      console.log("Tab or Shift keys are pressed...");
    } else {
      this.emailSubject.next(emailInput);
    }
  }

  checkEmail(email: string) {
    this.clearFlags();

    if (this.currentEmailInput === email) return;
    this.currentEmailInput = email;

    // var emailRegex = /^\w+([\.\+\-]?\w+)*\@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let emailCtrl = this.createAccountForm.get("email");
    if (emailCtrl.valid) {
      this.isEmailCheckLoading = true;

      let studioUserSignupStatus$ =
        this.studioUserSignupService.getUserStatus(email);
      let studioUserStatus$ =
        this.studioUserSignupService.getStudioUserStatus(email);

      let userStatus$ = combineLatest([
        studioUserSignupStatus$,
        studioUserStatus$,
      ]).pipe(
        tap(([signupStatus, studioUserStatus]) => {
          console.log(JSON.stringify(signupStatus, null, 2));
          console.log(JSON.stringify(studioUserStatus, null, 2));
        }),
        map(([signupStatus, studioUserStatus]) => {
          let combinedStatus: UserStatus = {
            ...signupStatus,
            studio_status: studioUserStatus.studio_status,
          };
          return combinedStatus;
        })
      );

      //MAIN:1 Identity Check
      userStatus$.subscribe(
        (identity) => {
          console.log(JSON.stringify(identity, null, 2));
          //const status = identity.status;

          if (identity.signup_status === this.UNVERIFIED) {
            //Step 1: Check to see if the user is signed up, but not verified their email.
            this.identityCheckDone = true;

            this.route.navigateByUrl("/unverified/" + email);
          } else if (
            identity.methods_status === this.EXISTS ||
            identity.studio_status === this.EXISTS ||
            identity.signup_status === this.VERIFIED
          ) {
            //Step 2: Display existing modal when either of the account exists.
            //Display existing account dialog
            this.displayExistingAcctModal = true;
            this.identityCheckDone = true;

            if (this.dialog.openDialogs.length === 0) {
              var dialogRef = this.dialog.open(ExistingAccountModal, {
                disableClose: false,
                width: "685px",
                maxWidth: "850px",
              });
              this.dialogSubscription = dialogRef
                .afterClosed()
                .subscribe((result) => {
                  this.criteria = [false, false, false, false];
                  this.clearForm();
                  this.clearFlags();
                });
            }
          } else if (identity.auth0_status === this.EXISTS) {
            //Step 3: Auth0 account exists (either myResearch or access to someother application.)
            //Let the user create the account without asking for password.
            this.isAccountExistsPlatformOnly = true;
            this.identityCheckDone = true;
            //Inform the auth0Observable about account existence. ...
            this.informAuth0Subject(true);
            this.accountAccessCheck(email);
          } else {
            //Step 4: This means -- It is a brand new account.
            //Check to see if the user's instituation (based on email domain) subscribed to TDM.

            //If subscribed  --Create account.
            //Else Display --- Trial block.
            this.informAuth0Subject(false);
            this.accountAccessCheck(email);
            this.identityCheckDone = true;
          }
        },
        (catchError) => {
          console.log(
            "Error occurred whilte checking the user status ",
            catchError
          );
          this.isEmailCheckLoading = false;
        },
        () => {
          console.log("Email-Check: User status completed...");
          this.isEmailCheckLoading = false;
        }
      );
    }
  }

  informAuth0Subject(exists: boolean) {
    this.auth0AccountExists$.next(exists);
  }

  accountAccessCheck(email: string) {
    this.emailSubscription = this.studioUserSignupService
      .getUserAccounts(email)
      .subscribe(
        (accountList) => {
          if (accountList.authAccountCount > 0) {
            this.institutionList = accountList.authAccount;
            this.checkAccess(this.institutionList[0].accountId);
            this.createAccountForm.patchValue({
              institution: this.institutionList[0].accountId,
            });
          } else {
            this.hasSubscription = false;
          }
        },
        (catchError) => {
          this.isEmailCheckLoading = false;
          console.log("Create Account Get Accounts Error: ", catchError);
        },
        () => {
          this.hasSubscriptionCheckDone = true;
          this.isEmailCheckLoading = false;
        }
      );
  }

  clearFlags() {
    this.isAccountExistsPlatformOnly = false;
    this.displayExistingAcctModal = false;
    this.identityCheckDone = false;
    this.institutionList = [];
    this.hasSubscription = false;
    this.hasSubscriptionCheckDone = false;
    this.errMsg = null;
    this.currentEmailInput = "";
  }

  checkAccess(institution) {
    this.errMsg = null;
    this.isAccessCheckLoading = true;
    this.studioUserSignupService.getAccount(institution).subscribe(
      (acctDetails) => {
        if (
          !acctDetails.hasMethodsAccess &&
          !acctDetails.hasWorkbenchAccess &&
          !acctDetails.hasUnlimitedWorkbenchAccess
        ) {
          this.hasSubscription = false;
        } else {
          this.hasSubscription = true;
        }
      },
      (error) => {
        this.isAccessCheckLoading = false;
        this.errMsg = error;
        this.hasSubscriptionCheckDone = false;
      },
      () => {
        this.identityCheckDone = true;
        this.hasSubscriptionCheckDone = true;
        this.isAccessCheckLoading = false;
      }
    );
  }

  checkPassword(password: string) {
    var capitalRegex = /.*[A-Z].*/;
    var lowerRegex = /.*[a-z].*/;
    var numberRegex = /.*\d.*/;
    //var specialRegex = /(?=.*[!@#$%^&*])/;
    var specialRegex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (password.length > 7) {
      this.criteria[0] = true;
    } else {
      this.criteria[0] = false;
    }
    if (capitalRegex.test(password)) {
      this.criteria[1] = true;
    } else {
      this.criteria[1] = false;
    }
    if (lowerRegex.test(password)) {
      this.criteria[2] = true;
    } else {
      this.criteria[2] = false;
    }
    if (numberRegex.test(password)) {
      this.criteria[3] = true;
    } else {
      this.criteria[3] = false;
    }
    if (specialRegex.test(password)) {
      this.criteria[4] = true;
    } else {
      this.criteria[4] = false;
    }
  }

  createAccount(userData) {
    if (userData.institution) {
      this.emailSubscription = this.studioUserSignupService
        .getAccount(userData.institution)
        .subscribe(
          (acctDetails) => {
            if (
              acctDetails.hasMethodsAccess ||
              acctDetails.hasWorkbenchAccess ||
              acctDetails.hasUnlimitedWorkbenchAccess
            ) {
              this.authSubscription = this.studioUserSignupService
                .createAccount(userData, acctDetails)
                .subscribe(
                  (response) => {
                    this.isAccountCreated = true;
                    this.titleService.setTitle("Verify Your Email");
                  },
                  (catchError) => {
                    console.log("Account Creation Error: ", catchError);
                    this.isAccountExistsPlatformOnly = false;
                  }
                );
            }
          },
          (catchError) => {
            console.log("Account Creation Error: ", catchError);
            this.isAccountExistsPlatformOnly = false;
          }
        );
    }
  }

  clearForm() {
    this.createAccountForm = this.formBuilder.group({
      email: ["", this.emailValidators],
      password: ["", this.passwordValidators],
      confirmPassword: "",
      institution: ["", Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
    });

    this.institutionList = [];
  }

  ngOnDestroy() {
    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
    if (this.emailSubjectSubscription) {
      this.emailSubjectSubscription.unsubscribe();
    }
  }

  get acceptTerms() {
    return this.createAccountForm.get("acceptTerms").value;
  }

  get passWordError(): boolean {
    return (
      this.createAccountForm.get("password").invalid &&
      this.createAccountForm.get("password").touched
    );
  }

  get emailError(): boolean {
    return (
      this.createAccountForm.get("email").invalid &&
      this.createAccountForm.get("email").touched
    );
  }

  get displayRequestTrail(): boolean {
    return (
      this.identityCheckDone &&
      this.hasSubscriptionCheckDone &&
      !this.hasSubscription &&
      !this.isAccessCheckLoading
    );
  }
}

@Component({
  selector: "existing-account-modal",
  templateUrl: "existing-account-modal.html",
  styleUrls: ["./create-account.component.scss"],
})
export class ExistingAccountModal {
  constructor(
    public dialogRef: MatDialogRef<ExistingAccountModal>,
    private auth: AuthService
  ) {}

  onLogin() {
    this.auth.login(location.origin);
    this.dialogRef.close();
  }
}

@Component({
  selector: "no-access-modal",
  templateUrl: "no-access-modal.html",
  styleUrls: ["./create-account.component.scss"],
})
export class NoAccessModal {
  constructor(public dialogRef: MatDialogRef<NoAccessModal>) {}
}

@Component({
  selector: "submission-modal",
  templateUrl: "submission-modal.html",
  styleUrls: ["./create-account.component.scss"],
})
export class SubmissionModal {
  constructor(public dialogRef: MatDialogRef<NoAccessModal>) {}
}
