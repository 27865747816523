import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, Subject, Subscription, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, switchMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MethodsAccessCheckResponse, User, UserInfo, VerifyEmailResponse, WorkbenchAccessCheckResponse } from '../workbenchDashboard/workbench.model';
import { StudioUserSignupService } from './studio-user-signup.service';
import { WorkbenchService } from '../workbenchDashboard/workbench.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  config$: Observable<TDMStudioConfig>;

  tdmStudioConfig: TDMStudioConfig;
  auth0Client$: Observable<Auth0Client> = this.loadConfigAndCreateAuthInstance();
  tdmUserData: any;
  tdmUser: User = {};
  //newTargetRoute: string;
  newTargetRoute: RouteParams;
  loginActivitySubject =  new BehaviorSubject<boolean>(false);
  loginActivity$ =  this.loginActivitySubject.asObservable();


  

  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap((res: boolean) => this.loggedIn = res)
  );

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  loggedIn: boolean = null;

  constructor(private router: Router, 
              private http: HttpClient, 
              private studioUserSignupService: StudioUserSignupService,
              private workbenchService: WorkbenchService) {
      
              this.auth0Client$ = this.loadConfigAndCreateAuthInstance();
    }

  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => {
          this.userProfileSubject$.next(user);
          console.log('user from Auth0 =', user);

          this.tdmUserData = user; // Setting up user data in userData var
          this.tdmUser = {...user};
          //localStorage.setItem('lastUserEmail', this.tdmUser.email);

        })
    );
  }

  getTokenSilently$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  localAuthSetup() {
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          return this.getUser$();
        }
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      console.log('checkAuth =>', response);
      this.loggedIn = !!response;
    });
  }

  login(redirectPath: string = "/analysis/dashboard") {
    redirectPath = localStorage.getItem("lastDashboard") ? localStorage.getItem("lastDashboard") : redirectPath;
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback`,
        appState: { target: redirectPath }
      });
    });
  }

  studioUser$: Observable<UserInfo> = this.http.get<UserInfo>("/api/um/user");
  analysisUser$: Observable<UserInfo> =  this.http.get<UserInfo>('/api/mum/user');
  wbAccessCheck$: Observable<WorkbenchAccessCheckResponse> = this.workbenchService.getWorkbenchAcessCheck(true, true);
  methodsAccessCheck$: Observable<MethodsAccessCheckResponse> =  this.workbenchService.createMethodsUser(false);
  emailVerified$: Observable<VerifyEmailResponse> = this.getUser$().pipe(
                                                      switchMap((res: User) => this.studioUserSignupService.getUserStatus(res.name))
                                                    )


  handleAuthCallback() {
    let targetRoute: string; // Path to redirect  after login processsed

    const authComplete$ = this.handleRedirectCallback$.pipe(
      tap(cbRes => {
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/home';
      }),
      concatMap(() => {
        // Redirect callback complete; get user and login status
        return combineLatest([
          this.emailVerified$,
          this.isAuthenticated$,
          this.wbAccessCheck$.pipe(
            catchError((err) => {
              console.log('Error ocured: while wb access check...');
              return of(null);
            })
          ), 
          this.methodsAccessCheck$.pipe(
            catchError((err) => {
              console.log('Error occured: while methods access check...');
              return of(null)
            })
          )
          ]);
      })
    );

    /**
     * singupAndAccessInfo$ observable returns objet contains accountAccess information & singupUserInfomation
     *    example: { signupUserInfo, acountAcessInfo }
     *              
     */
    authComplete$.subscribe(([emailVerifiedResp, loggedIn, wbAccessCheckResp, methodsAccessCheckResp]) => {

      if(wbAccessCheckResp !== null) {
        this.tdmUser.hasWorkbenchAccessResp =  wbAccessCheckResp;

      }
      if(methodsAccessCheckResp !== null) this.tdmUser.hasMethodsAccessResp =  methodsAccessCheckResp;
      if(emailVerifiedResp !==null) this.tdmUser.verifyEmailResp = emailVerifiedResp;
      

      this.loggedIn = loggedIn;
      localStorage.setItem('user', JSON.stringify(this.tdmUser));

      //this.newTargetRoute = this.navigateToDashboardBasedOnUserType(wbAccessCheckResp,methodsAccessCheckResp,  emailVerifiedResp);
      this.newTargetRoute = this.getNewRouteAfterAuthentication(wbAccessCheckResp,methodsAccessCheckResp,  emailVerifiedResp);

      //getNewRouteAfterAuthentication
      this.router.navigate([this.newTargetRoute.routePath], { queryParams: { 'r': this.newTargetRoute.params.value}});

      },
      error => {
        console.error("error occured while handling callback", error);
      },
      () => {
       // this.router.navigate([this.newTargetRoute]);
      });
    }



  getNewRouteAfterAuthentication( wbAccessCheckResp: WorkbenchAccessCheckResponse,
                                    methodsAccessCheckResp: MethodsAccessCheckResponse,
                                    emailVerifiedResp: VerifyEmailResponse): RouteParams {

      let newRoute: string = '/home';
      let displayWelcomePage = localStorage.getItem("displayWelcomePage");
      let lastDashboard = localStorage.getItem('lastDashboard');
      let lastUserEmail = localStorage.getItem('lastUserEmail');
      let queryParams: {paramName?: string, value?: string} = {};

                                  

      //                             
      if(this.hasAccessToApp()) {

          //Display welcome page for the first time. 
          if( (!displayWelcomePage || displayWelcomePage === 'true')   ||
                (lastUserEmail !== this.tdmUser.email && displayWelcomePage === 'false')) {

                  newRoute = '/welcome';

          } else if(lastDashboard) {
            if(lastDashboard === '/analysis/dashboard' && !this.hasAccessToVisualization() && this.hasAccessToWorkBench()) {
              newRoute = '/workbenchdashboard';
      
            } else if(lastDashboard === '/workbenchdashboard' && !this.hasAccessToWorkBench && this.hasAccessToVisualization())  {
              newRoute = '/analysis/dashboard';
            } else {
              newRoute = lastDashboard;
            }
            //newRoute = (lastDashboard !== 'null' ||  lastDashboard !== 'null') ? localStorage.getItem("lastDashboard") :'/analysis/dashboard';
            this.tdmUser.dashboardPath = newRoute;
            localStorage.setItem("lastDashboard", newRoute);
          } 

      } else if(emailVerifiedResp !=null && emailVerifiedResp.signup_status === 'unverified' ) {
        newRoute = '/unverified/'+this.tdmUser.email;
  
      } else if(emailVerifiedResp && emailVerifiedResp.auth0_status && emailVerifiedResp.auth0_status === 'exists' ) {
        newRoute = '/unauthorized'
        queryParams = { 'paramName': 'r', 
                        'value' : 'Your-Account-Is-Not-Signed-Up'};
      
      }

      localStorage.setItem('user', JSON.stringify(this.tdmUser));
      this.loginActivitySubject.next(this.loggedIn);

      return { routePath: newRoute, params: queryParams}


  }

  logout() {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      let user =  this.getCurrentlyLoggedInUser();
      let lastDashboard = localStorage.getItem("lastDashboard");
      let displayWelcomePage = localStorage.getItem("displayWelcomePage");
      let lastUserEmail =  (user !== null) ? user.email: null;


      localStorage.clear();
      if(lastDashboard) localStorage.setItem("lastDashboard", lastDashboard);
      if(displayWelcomePage) localStorage.setItem("displayWelcomePage", displayWelcomePage);
      if(lastUserEmail) localStorage.setItem('lastUserEmail', lastUserEmail);
      client.logout({
        client_id: this.tdmStudioConfig.clientID,
        returnTo: `${window.location.origin}`
      });
    });
  }

  clearAppSession() {
      let user =  this.getCurrentlyLoggedInUser();
      let lastDashboard = localStorage.getItem("lastDashboard");
      let displayWelcomePage = localStorage.getItem("displayWelcomePage");
      let lastUserEmail =  (user !== null) ? user.email: null;


      localStorage.clear();
      if(lastDashboard) localStorage.setItem("lastDashboard", lastDashboard);
      if(displayWelcomePage) localStorage.setItem("displayWelcomePage", displayWelcomePage);
      if(lastUserEmail) localStorage.setItem('lastUserEmail', lastUserEmail);
      this.loginActivitySubject.next(false);
      this.router.navigate(['/home']);
  }

  loadConfigAndCreateAuthInstance(): Observable<Auth0Client> {

    //Cache it once if configs value is false. 
    if(!this.config$) {

      this.config$ = this.http.get<TDMStudioConfig>('/api/ssoidentity/config/TDMStudio')
                              .pipe(shareReplay(1));
    }


    return this.config$
      .pipe(
            tap(() => console.log('config request executed!')),
            switchMap((config: TDMStudioConfig) => {
              this.tdmStudioConfig =  config;
              return createAuth0Client({
                domain: config.domainInfo.proquestLogin,
                client_id: config.clientID,
                audience : config.api_audience,
                leeway: 300,
                redirect_uri: `${window.location.origin}/callback`,
                contextHostURL: `${window.location.origin}/`
              });
            }),
            shareReplay(1)
      );
  }


  getCurrentlyLoggedInUser(): User {
    const user: User =  JSON.parse(localStorage.getItem('user'));
    return user;
  }

  hasAccessToWorkBench(): boolean {
    let user = this.getCurrentlyLoggedInUser();
    return  user &&
            user.hasWorkbenchAccessResp &&
            (user.hasWorkbenchAccessResp.create ||
            user.hasWorkbenchAccessResp.read ||
            user.hasWorkbenchAccessResp.accountType === 'limited');
  }

  preLoadWorkbenchs(): boolean {
    let user = this.getCurrentlyLoggedInUser();

    return user &&
            user.hasWorkbenchAccessResp &&
            user.hasWorkbenchAccessResp.read


  }

  isLimitedSubscription() {

    let user = this.getCurrentlyLoggedInUser();

    return user &&
            user.hasWorkbenchAccessResp &&
            !user.hasWorkbenchAccessResp.create &&
            !user.hasWorkbenchAccessResp.read &&
            (user.hasWorkbenchAccessResp.accountType === 'limited' ||
            user.hasWorkbenchAccessResp.accountType === 'unknown');
  }

  hasAccessToVisualization(): boolean {
    let user = this.getCurrentlyLoggedInUser();

    return  user && 
            user.hasMethodsAccessResp &&
            user.hasMethodsAccessResp.hasMethodsAccess;

  }

  hasAccessToApp() {
    return this.hasAccessToWorkBench() || this.hasAccessToVisualization();
  }
 }


 
export class TdmUser {
  constructor(private email: string, private firstname?: string, private lastname?: string) {}
}

 type RouteParams =  {
                          routePath: string, 
                          params: {paramName?: string, value?: string}
                      }

export interface TDMStudioConfig {
  clientID: string;
  domain: string;
  api_audience: string;
  domainInfo?: {
    tenant: string;
    proquestLogin: string;
    tokenIssuer: string;
  };

}

