import { Component, ElementRef, HostListener, OnInit } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import {
  AccountAccessInfo,
  SignupUserInfo,
  User,
} from "../workbenchDashboard/workbench.model";
import { Location } from "@angular/common";
import { DocumentationComponent } from "../analysis/documentation/documentation.component";
import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "tdms-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"],
})
export class AppHeaderComponent implements OnInit {
  tdmUser: User;
  isUser = false;
  accountAccessInfo: AccountAccessInfo;
  displayTopNavVizLink = false;
  displayTopNavWorkbenchLink = false;

  isToggle: boolean = false;
  isOpen: boolean = false;
  isAnalysis: boolean = false;
  isWorkbench: boolean = false;
  isHelp: boolean = false;
  isDropdownMenu: boolean = false;
  isRequestAccess: boolean = false;
  resize: Observable<Window>;
  resizeSubscription: Subscription;
  errMsg;
  displayOnboard = false;

  isContactDetailsVisible: boolean = false;

  @HostListener("keydown.escape")
  hideModal(): void {
    this.isRequestAccess = false;
  }

  constructor(
    public auth: AuthService,
    private location: Location,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private eleRef: ElementRef
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setDashboard();
      }
    });
  }

  ngOnInit() {
    this.auth.loginActivity$.subscribe((activity) => {
      this.setUser();
    });

    if (this.authService.getCurrentlyLoggedInUser() !== null) {
      this.setUser();
    } else {
      this.isUser = this.checkIsUser();
    }

    //Call the resize function whenever a resize event occurs.
    this.resize = fromEvent<Window>(window, "resize").pipe(debounceTime(100));

    // When window resizes close menu
    this.resizeSubscription = this.resize.subscribe(() => {
      this.isDropdownMenu = false;
    });

    //isHelpcheck...
    if (this.location.path().includes("/analysis")) this.isHelp = true;

    //Which Dashboard is selected
    //this.setDashboard();
    this.router.events.subscribe(() => {
      let currentUrl = this.router.url.substring(1);
      let displayWecomePage = localStorage.getItem("displayWelcomePage");
      if (
        currentUrl === "workbenchdashboard" &&
        (displayWecomePage === "true" || !displayWecomePage)
      ) {
        this.displayOnboard = true;
        localStorage.setItem("displayWelcomePage", "false");
      }
    });
  }

  ngOnchanges(changes) {}

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  goToHomePage() {
    this.router.navigate(["home"]);
  }

  showDashboardLinks() {
    return (
      this.tdmUser &&
      this.tdmUser !== undefined &&
      this.tdmUser.verifyEmailResp &&
      !this.location.path().includes("/welcome")
    );
  }

  goToWorkbench() {
    let user: User = JSON.parse(localStorage.getItem("user"));

    if (this.authService.isLimitedSubscription()) {
      this.isRequestAccess = true;
    } else {
      this.router.navigate(["/workbenchdashboard"]);
    }
  }

  goToAnalysis() {
    this.displayOnboard = false;
    let route = "/analysis/dashboard";
    localStorage.setItem("lastDashboard", route);
    this.router.navigate([route]);
  }

  setUser() {
    this.tdmUser = this.authService.getCurrentlyLoggedInUser();
    this.isUser = this.checkIsUser();

    this.displayTopNavWorkbenchLink = this.navBarWorkbenchLinkCheck();

    this.displayTopNavVizLink =
      this.tdmUser !== null &&
      this.tdmUser.hasMethodsAccessResp &&
      this.tdmUser.hasMethodsAccessResp.hasMethodsAccess;
  }

  checkIsUser() {
    return (
      (this.tdmUser && this.authService.hasAccessToWorkBench()) ||
      this.authService.hasAccessToVisualization()
    );
  }

  navBarWorkbenchLinkCheck() {
    return (
      this.tdmUser !== null &&
      this.tdmUser.hasWorkbenchAccessResp &&
      (this.tdmUser.hasWorkbenchAccessResp.create ||
        this.tdmUser.hasWorkbenchAccessResp.read ||
        this.tdmUser.hasWorkbenchAccessResp.accountType === "limited")
    );
  }

  setDashboard() {
    if (this.location.path().includes("/analysis")) {
      this.isAnalysis = true;
      this.isWorkbench = false;
      this.isHelp = true;
    } else if (
      this.location.path().includes("/home") ||
      this.location.path().includes("/unauthorized") ||
      this.location.path().includes("/welcome")
    ) {
      this.isAnalysis = false;
      this.isWorkbench = false;
    } else {
      this.isHelp = false;
      this.isAnalysis = false;
      this.isWorkbench = true;
    }
  }

  openDocumentation() {
    this.dialog.open(DocumentationComponent, {
      disableClose: true,
      width: "90vw",
      height: "90vh",
      data: { tab: this.getStartingTab() },
    });
  }

  getStartingTab(): string {
    if (
      this.location.path().includes("/analysis/search") ||
      this.location.path().includes("/analysis/refinecontent") ||
      this.location.path().includes("/analysis/createdataset")
    ) {
      return "Create a Project";
    } else if (this.location.path().includes("/analysis/viz/geoanalysis")) {
      return "Geographic Analysis";
    } else if (this.location.path().includes("/analysis/viz/tm")) {
      return "Topic Modeling";
    } else if (this.location.path().includes("/analysis/viz/sa")) {
      return "Sentiment Analysis";
    }
    return "Visualization Dashboard";
  }

  openHelpWindow() {
    if (this.isAnalysis) {
      this.openDocumentation();
    } else {
      this.displayOnboard = true;
    }
  }

  onCloseOnBoardEvent(isClose: boolean) {
    if (isClose) {
      this.displayOnboard = false;
    }
  }

  toggleContactDetails() {
    this.isContactDetailsVisible = !this.isContactDetailsVisible;
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: Event) {
    if (!this.eleRef.nativeElement.contains(event.target)) {
      this.isContactDetailsVisible = false;
    }
  }
}
