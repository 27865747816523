<div class="container" *ngIf='isLoading'>
    <div class="center">
        <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
    </div>
</div>


<div class="container" *ngIf="isLoadingComplete()">
    <div class="header">
        <div class="title_div">
            <div class="flex_row">
                <div class="title">Your Datasets</div>
                <div class="table_count">(using {{getProjectCount()}} of {{maxProjects}})</div>
            </div>
            <div class="create_btn_wrapper">
                <div class="subtitle">Manage and interact with your custom datasets using visualization methods.</div>
                <div class="btn_wrapper">
                    <div *ngIf="selectedProjects.length > 0"
                        class="trash_btn"
                        (click)="deleteSelected()"
                        (keyup.enter)="deleteSelected()"
                        tabindex="0"
                        role="button"
                        aria-label="delete selected">   
                        <i class="bi bi-trash"></i>
                    </div>
                    <div *ngIf="selectedProjects.length == 0"
                        class="hidden_trash_btn"
                        tabindex="0"
                        role="button"
                        aria-label="delete selected, none selected">
                    </div>
                    <button class="create_btn" (keydown.enter)="goToCreation()" (click)="goToCreation()" *ngIf="yourProjects && getProjectCount() < maxProjects">
                        + Create New Dataset
                    </button>
                    <button class="disable_btn" disabled *ngIf="!(yourProjects && getProjectCount() < maxProjects)">
                        + Create New Dataset
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="table" *ngIf="yourProjects.length > 0">
        <div class="table_header">
            <div class="select_col">
                <div class="checkbox"
                    tabindex="0"
                    role="checkbox"
                    (click)="selectAllProjects()"
                    (keyup.enter)="selectAllProjects()">
                    <i class="bi bi-check2" *ngIf="isAllSelected()"></i>
                </div>
            </div>
            <div class="name_col">NAME</div>
            <div class="date_range_col">DATE RANGE</div>
            <div class="search_col">SEARCH</div>
            <div class="data_source_col">DATA SOURCE</div>
            <div class="docs_col">DOC COUNT</div>
            <div class="date_created_col">DATE CREATED</div>
            <div class="vis_col"></div>
            <div class="delete_col"></div>
        </div>

        <ng-template ngFor let-project [ngForOf]="yourProjects">

            <div [ngClass]="isChecked(project.id) ? 'table_rows expanded' : 'table_rows'"
                tabindex="0"
                role="row"
                (click)="selectProject(project)"
                (keyup.enter)="selectProject(project)">
                <div class="select_col">
                    <div class="checkbox" role="checkbox" attr.aria-checked="isChecked(project.id)"> <i *ngIf="isChecked(project.id)" class="bi bi-check2"></i></div>
                </div>
                <div class="name_col"><b>{{ (!isChecked(project.id) && (project.name.length > 225)) ? (project.name | slice:0:225) + '...' : project.name }}</b></div>
                <div class="date_range_col">{{ getDateRange(project) }}</div>
                <div class="search_col">{{ (!isChecked(project.id) && (project.search.query.length > 225)) ? (project.search.query | slice:0:225) + '...' : project.search.query }}</div>
                <div class="data_source_col">
                    <div class="data_source">
                        <p *ngIf="getPublications(project).length > 0 || getPubs(project).length > 0"><b>Publication Titles</b></p>
                        <p *ngFor = "let pub of getPublications(project)">{{pub}}</p>
                        <p *ngFor = "let pub of getPubs(project)">{{pub}}</p>
                        <p *ngIf="getDbs(project).length > 0" [ngClass]="getPubs(project).length > 0 ? 'space' : 'no_space'"><b>Databases</b></p>
                        <p *ngFor = "let db of getDbs(project)">{{db}}</p>
                    </div>
                </div>
                <div class="docs_col">{{ project.docCount }}</div>
                <div class="date_created_col">{{ getDate(project) }}</div>
                <div class="vis_col" *ngIf="project.status !== 'deleting'">
                    <div class="vis_wrapper">
                        <div class="method_info" *ngIf="this.isGeoAnalysis(project) && (getGeoStatus(project) === 'completed' || getGeoStatus(project) === 'failed')" 
                                tabindex="0"
                                role="link"
                                attr.aria-label="Geographic Analysis"
                                (click)="goToGeoAnalysis(project)"
                                (keyup.enter)="goToGeoAnalysis(project)">
                            <img class="globe" src='../../../assets/images/globe.svg' />
                            <p class="method_text">Geographic Analysis</p>
                        </div>
                        <div class="method_info" *ngIf="isTopicAnalysis(project) && (getTopicStatus(project) === 'completed' || getTopicStatus(project) === 'failed')"
                                tabindex="0"
                                role="link"
                                attr.aria-label="Topic Modeling"
                                (click)="goToTopicAnalysis(project)"
                                (keyup.enter)="goToTopicAnalysis(project)">
                            <img class="topic" src='../../../assets/images/topic-icon.svg' />
                            <p class="method_text">Topic Modeling</p>
                        </div>
                        <div class="method_info" *ngIf="isSentimentAnalysis(project) && (getSentimentStatus(project) === 'completed' || getSentimentStatus(project) === 'failed')"
                                tabindex="0"
                                role="link"
                                attr.aria-label="Sentiment Analysis"
                                (click)="goToSentimentAnalysis(project)"
                                (keyup.enter)="goToSentimentAnalysis(project)">
                            <img class="topic" src='../../../assets/images/sa-icon.svg' />
                            <p class="method_text">Sentiment Analysis</p>
                        </div>
                        <div class="loading status" *ngIf="isInProgress(project)">
                            <i class="bi bi-clock"></i>
                            <p>In-Progress</p>
                        </div>
                        <div class="loading_list">
                            <p class="loading_text" *ngIf="this.isGeoAnalysis(project) && !(getGeoStatus(project) === 'completed' || getGeoStatus(project) === 'failed')">Geographic Analysis</p>
                            <p class="loading_text" *ngIf="isTopicAnalysis(project) && !(getTopicStatus(project) === 'completed' || getTopicStatus(project) === 'failed')">Topic Modeling</p>
                            <p class="loading_text" *ngIf="isSentimentAnalysis(project) && !(getSentimentStatus(project) === 'completed' || getSentimentStatus(project) === 'failed')">Sentiment Analysis</p>
                        </div>
                    </div>
                </div>
                <div class="vis_col" *ngIf="project.status == 'deleting'">
                    <div class="deleting status" >
                        <i class="bi bi-x-circle"></i>
                        <p>Deleting</p>
                    </div>
                </div>
                <div class="delete_col"> 
                    <div class="trash_btn"
                        (click)="delete([project]); selectProject(project);"
                        (keyup.enter)="delete([project]); selectProject(project);"
                        tabindex="0"
                        role="button"
                        aria-label="delete">   
                        <i class="bi bi-trash"></i>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>