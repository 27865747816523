import { Routes, RouterModule } from '@angular/router';

import { DatasetComponent } from './dataset.component';
import { PublicationRefineContentComponent } from './selectContent/publication-refinecontent/publication-refinecontent.component';
import { PublicationCreatedatasetComponent } from './selectContent/publication-createdataset/publication-createdataset.component';

const DATASET_ROUTES: Routes = [
  { path: 'refinecontent_', component: PublicationRefineContentComponent},
  { path: 'createdataset_', component: PublicationCreatedatasetComponent}

];

export const DatasetRoutingModule = RouterModule.forChild(DATASET_ROUTES);
