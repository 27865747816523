
<div class="image_div" fxLayout="row" >
    <div class="wbname" fxLayoutAlign="left center" fxLayoutAlign.md="space-evenly center" fxLayout="row" fxLayout.lt-lg="column">
        <div ngClass="title" ngClass.md="title-md" ngClass.lt-md="title-ltMd">{{workbench}}</div>
        <div ngClass="line" ngClass.md="line-md" ngClass.lt-md="line-ltMd"></div>
        <h1 ngClass="title" ngClass.md="title-md" ngClass.lt-md="title-ltMd"><b>Create New Dataset</b></h1>
    </div>
    <div class="progress_div" fxLayoutAlign="center center" fxFlex>
        <div  class="progress_container" fxLayout="column" fxLayoutAlign="center center">
            <div class="progress_bar" fxLayout="row">
                <div [ngClass]="stage === 'find' ? 'current dot' : 'complete dot'">A</div>
                <hr [ngClass]="getRodClass()"/>
                <div [ngClass]="{'dot':true, 'unhighlighted':stage === 'find', 'current':stage === 'refine', 'complete':stage === 'create'}">A</div>
                <hr [ngClass]="stage === 'create' ? 'highlighted rod' : 'unhighlighted rod'"/>
                <div [ngClass]="stage === 'create' ? 'current dot' : 'unhighlighted dot'">A</div>
            </div>
            <div class = "progress_labels" fxLayout="row" fxLayoutAlign="start center">
                <div class="label first" [ngClass]="(stage === 'find') ? 'highlighted' : 'unhighlighted'">
                        <a routerLink='/dataset'>Find Content</a>
                </div>
                <div *ngIf="(stage === 'create')"class="unhighlighted label middle">
                    <a *ngIf="stage === 'create'" routerLink='/dataset/refinecontent'>Refine Content</a>
                </div>
                <div *ngIf="!(stage === 'create')" [ngClass]="(stage === 'refine') ? 'highlighted' : 'unhighlighted'" class="label middle">Refine Content</div>
                <div [ngClass]="stage === 'create' ? 'highlighted' : 'unhighlighted'" class="label last">Create Dataset</div>
            </div>
        </div>
    </div>    
</div>