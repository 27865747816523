import { NgModule } from "@angular/core";
import { DbFacetComponent } from "./db-facet/db-facet.component";
import { FilterpanelComponent } from "./filterpanel/filterpanel.component";
import { DocTypeFacetComponent } from "./doc-type-facet/doc-type-facet.component";
import { SourceTypeFacetComponent } from "./source-type-facet/source-type-facet.component";
import { CheckboxGroupComponent } from "./checkbox-group/checkbox-group.component";
import { DateFacetComponent } from "./date-facet/date-facet.component";
import { PubSelectModalComponent } from "./filterpanel/pubselectmodal.component";
import { YearCardComponent } from "./date-facet/year-card.component";
import { CommonModule } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MaterialModule } from "src/app/material/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedModule } from "src/app/shared/shared.module";
import { WbPubResultsComponent } from "../wb-pub-results/wb-pub-results.component";

@NgModule({
  declarations: [
    DbFacetComponent,
    FilterpanelComponent,
    DocTypeFacetComponent,
    SourceTypeFacetComponent,
    CheckboxGroupComponent,
    DateFacetComponent,
    PubSelectModalComponent,
    YearCardComponent,
    WbPubResultsComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MaterialModule,
    FlexLayoutModule,
    MatCheckboxModule,
    SharedModule,
  ],
  exports: [
    DbFacetComponent,
    FilterpanelComponent,
    DocTypeFacetComponent,
    SourceTypeFacetComponent,
    CheckboxGroupComponent,
    DateFacetComponent,
    PubSelectModalComponent,
    YearCardComponent,
    WbPubResultsComponent,
  ],
  entryComponents: [PubSelectModalComponent],
})
export class FacetsModule {}
