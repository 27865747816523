import { Component, OnInit } from "@angular/core";
import { PublicationService } from "../publication-results/publication-refinement.service";
import { FormControl } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as _m from "moment";

@Component({
  selector: "tdms-datefacet",
  templateUrl: "./datefacet.component.html",
  styleUrls: ["./datefacet.component.scss"],
})
export class DatefacetComponent implements OnInit {
  displayFilters$ = this.pubservice.displayFilter$;

  fromDate: Date;
  toDate: Date;
  maxDate: Date;

  toDateMin: Date = null;

  fromDateC: FormControl;
  toDateC: FormControl;

  inputFromDate: string = "";
  inputToDate: string = "";

  currSelfromDate: Date;
  currSelToDate: Date;

  constructor(private pubservice: PublicationService) {}

  ngOnInit() {
    this.fromDateC = new FormControl();
    this.toDateC = new FormControl();
    this.populateValuesFromLocalStorage();
    this.maxDate = new Date();
  }

  private populateValuesFromLocalStorage() {
    let fDate = JSON.parse(localStorage.getItem("fromDate"));
    let tDate = JSON.parse(localStorage.getItem("toDate"));
    if (fDate != null && fDate.length > 0) {
      let tmpFdate = fDate.substring(0, 10).split("-");

      tmpFdate = tmpFdate[1] + "/" + tmpFdate[2] + "/" + tmpFdate[0];

      this.fromDateC = new FormControl(new Date(tmpFdate));
      this.toDateMin = new Date(new Date(tmpFdate));
    }

    if (tDate != null && tDate.length > 0) {
      let date = tDate.substring(0, 10).split("-");
      date = date[1] + "/" + date[2] + "/" + date[0];
      this.toDateC = new FormControl(new Date(date));
    }
  }

  inputedFromDate(input) {
    this.inputFromDate = input.trim();
  }

  inputedToDate(input) {
    this.inputToDate = input.trim();
  }

  saveDate(whichDate: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value === null) {
      if (_m(this.inputFromDate, "MM/YYYY").isValid()) {
        var date = _m(this.inputFromDate, "MM/YYYY").toDate();
        this.inputFromDate = "";
        event.value = date;
        this.fromDateC = new FormControl(date);
      } else if (_m(this.inputToDate, "MM/YYYY").isValid()) {
        var date = _m(this.inputToDate, "MM/YYYY").toDate();
        date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.inputFromDate = "";
        event.value = date;
        this.toDateC = new FormControl(date);
      } else {
        localStorage.removeItem(whichDate);
      }
    }

    if (event.value !== null) {
      if (whichDate === "fromDate") {
        // _m(new Date(date)).utcOffset(0,false).format('MMM Do, YYYY');

        if (
          this.inputFromDate.length == 4 &&
          _m(this.inputFromDate, "YYYY").isValid()
        ) {
          var date = new Date(this.inputFromDate);
          date.setDate(date.getDate() + 1);
          event.value = date;
          this.fromDateC = new FormControl(date);
        }

        localStorage.setItem(
          "fDate",
          JSON.stringify(_m(new Date(this.fromDateC.value)).utcOffset(0, true))
        );
        this.currSelfromDate = this.fromDateC.value;
        this.toDateMin = new Date(this.fromDateC.value);
        //this.toDateMin.setDate(event.value.getDate());
      }

      if (whichDate === "toDate") {
        if (
          this.inputToDate.length == 4 &&
          _m(this.inputToDate, "YYYY").isValid()
        ) {
          var date = new Date(this.inputToDate);
          console.log(date);
          date.setFullYear(date.getFullYear() + 1);
          console.log(date);
          event.value = date;
          this.toDateC = new FormControl(date);
        }

        this.currSelToDate = this.toDateC.value;
        //this.setToDateMin();
        localStorage.setItem(
          "tDate",
          JSON.stringify(_m(new Date(this.toDateC.value)).utcOffset(0, true))
        );
      }
    }

    this.setToDateMin();

    this.pubservice.setDateFilterChange(true);

    console.log(event.value);
  }

  isYearFormat(input: string) {
    if (_m(input, "YYYY").isValid()) {
      var date = new Date(input);
      date.setDate(date.getDate() + 1);
      console.log(date);
    }
  }

  private setToDateMin() {
    let fDate = localStorage.getItem("fromDate");
    if (fDate != null) this.toDateMin = new Date(JSON.parse(fDate));
  }
}
